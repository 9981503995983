<template>
  <div id="searchbar-div">
    <input  v-on:keyup.enter="searchValues"  type="text" id="searchbar" v-model="selectedSearchModel" placeholder="Search..."/>
    <button class="app-button primary-button" @click="searchValues">Search</button>
    <button class="app-button secondary-button" @click="clearSearch">Clear</button>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  data(){
    return {
      selectedSearchModel: ""
    }
  },
  methods: {
    searchValues(){
      this.$emit('searchList', this.selectedSearchModel)
    },
    clearSearch(){
      this.selectedSearchModel=""
      this.$emit('resetList')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#searchbar-div{
  margin:1rem auto;
  text-align: center;
}
#searchbar{
  width: 50%;
  line-height: 1.5;
  height: 2rem;
  font-size: 16px;
  background-color: rgb(255, 255, 255);
  color:rgba(0, 0, 0, 0.829);
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(75, 110, 75);
  border-radius: 4px;
  box-sizing: border-box;
  outline:none;
}

</style>
